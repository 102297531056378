@use '@angular/material' as mat;
@import "~src/styles/helpers/mixins/grid";
.seq-theme {
  .mat-card {
    margin-bottom: 24px;
    padding: 24px;
    @media screen and (max-width: 835px) {
      margin-bottom: 60px;
    }

    .mat-card-header {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: row;
      // align-items: center;
      white-space: wrap;
      font-size: 1.4em;
      font-weight: 100;
      color: rgba(0, 0, 0, 0.87);
      padding: 0 0 24px 0;
      margin: 0;
      gap: 12px;
      @media screen and (max-width: 1365px) {
        width: 100% !important;
        flex-wrap: wrap;
      }

      button {
        // margin-left: 10px;
        flex-grow: 0;
        // margin-left: 0px !important;
      }

      .mat-card-header-text {
        display: none;
      }
      .spacer1 {
        display: none !important;
      }
    }

    .mat-card-footer {
      display: flex;
      margin: 0;
      padding-top: 20px;

      button {
        margin-left: 10px;
        flex-grow: 0;
      }
    }

    .mat-card-content {
      font-size: 16px;

      span.details_label {
        display: block;
        font-size: 14px;
        color: var(--primary-color);
        font-weight: 600;
      }

      p {
        // display: flex;
        // flex-direction: column;
        margin: 12px 0;

        span {
          display: inline;
        }
      }
    }

    .card-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1em;
    }

    .card-column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(50% - 12px);

      .mat-form-field {
        width: 100%;
      }
      &.width-30 {
        flex: 1 1 calc(30% - 12px);
        width: calc(30% - 12px);
      }
    }

    .card-column-33 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(33.33% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(100% - 0px);
        margin-bottom: 15px;
      }
    }

    .card-column-25 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(25% - 12px);
    }

    .card-column-15 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(15% - 12px);
    }

    .card-column-60 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(60% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(60% - 12px);  
      }
    }

    .card-column-50 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(50% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(50% - 12px);
      }
    }

    .card-column-10 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(10% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(10% - 12px);
      }
    }

    .card-column-40 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(40% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(30% - 12px);  
      }
    }

    .card-column-45 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(45% - 12px);
      @media screen and (max-width: 598px) {
        width: calc(100% - 12px);  
      }
    }

    .card-column-75 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(75% - 12px);
    }
    .card-column-100 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(111% - 12px);
    }

    .card-column-100 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(100% - 12px);
    }

    .card-column-third {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(33% - 12px);
    }

    .card-column-two-third{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(67% - 12px);
    }

    .group-title {
      color: var(--primary-color);
      margin-bottom: 12px;
    }

    .group-title.group-subtitle {
      color: var(--primary-color);
      margin-bottom: 24px;
    }

    .group-subtitle {
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 24px;
    }

    .mobile-label {
      display: none;
    }

    .mat-row {
      min-height: 56px;
    }
  }
  .caseload-inner{
    .mat-card-header {
      .mat-card-header-text{
        display: block!important;
        margin: 0;
      }
    }
    .mat-paginator-range-label{
      margin: 0!important;
    }
  }
  .search_by {
    border: 1px solid rgba(0, 0, 0, 0.24);
    padding: .25em .75em;
    margin: 0 12px 0 0;
    border-radius: 4px;
    font-size: 0.95em;
    line-height: 1.125!important;

    .mat-form-field-infix {
      width: 300px;
      border-top: 0;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
      margin-top: 2.5em;
      font-size: 90%;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper,
    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
      height: 0;
      background-color: transparent !important;
    }

    .mat-form-field-suffix .mat-icon-button {
      width: 24px;
      height: 24px;

      &:hover {
        background: transparent;
      }

      .mat-icon {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.64);
      }
    }
  }

  // .search_by.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  // .search_by .mat-input-element::placeholder {
  //   display: none;
  //   opacity: 0;
  // }

  // .search_by .mat-input-element::placeholder,
  // .search_by .mat-input-element::-webkit-input-placeholder,
  // .search_by .mat-input-element::-moz-placeholder {
  //   opacity: 0;
  // }

  .filter_by {
    max-width: 300px;
    font-size: 16px;
    padding: 0.35em 0.75em;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;

    .mat-select-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  ::selection {
    background: var(--secondary-color);
    color: #fff;
    text-shadow: none;
  }
  ::-webkit-selection {
    background: var(--secondary-color);
    color: #fff;
    text-shadow: none;
  }
  ::-moz-selection {
    background: mat-color($seq-accent);
    color: #fff;
    text-shadow: none;
  }

  .color-primary {
    color: mat-color($seq-primary);
  }
  .mat-stroked-button:not([class*="mat-elevation-z"]),
  .seq-theme .mat-flat-button:not([class*="mat-elevation-z"]) {
    border-color: rgba(0, 0, 0, 0.24);
  }
  .color-accent {
    color: mat-color($seq-accent);
  }

  .chip {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 4px;
    align-items: center;
    margin: 4px;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.87);
    .mat-checkbox-inner-container {
      height: 16px;
      width: 16px;
    }
    .mat-checkbox-layout .mat-checkbox-label {
      line-height: 18px;
    }
  }

  .chip-parent {
    position: relative;

    .chip-label {
      padding-left: 44px;
    }

    .chip-child {
      position: absolute;
      margin: 0;
      padding: 7px 12px 7px 10px;
      border: 0;
      border-radius: 4px 0 0 4px;
      top: 0;
      left: 0;
    }
  }

  .primary {
    background-color: mat-color($seq-primary);
    color: white;
    border: 1px solid mat-color($seq-primary);
  }
  .small {
    color: rgba(0, 0, 0, 0.64);
  }

  .settings .mat-tab-header {
    .mat-icon {
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  hr {
    margin-bottom: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .label-hr {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 16px 0;
    color: var(--primary-color);

    span {
      font-size: 1.3em;
      font-weight: 100;
      padding-right: 8px;
      color: mat-color($seq-primary);
    }

    &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }
    &:before {
      content: "";
      width: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }

    &:after {
      margin-left: 0.25em;
    }
  }

  .mobile,
  .on-mobile {
    display: none !important;
  }

  .width-0 {
    width: 0%;
    display: none;
    padding: 0 !important;
    margin: 0 !important;
  }

  .width-5 {
    flex: 1 1 calc(5% - 12px);
    width: calc(5% - 12px);
  }
  .width-7 {
    flex: 1 1 calc(7% - 12px);
    width: calc(7% - 12px);
  }
  .width-10 {
    // flex: 1 1 calc(10% - 12px);
    width: calc(10% - 12px);
  }
  .width-12{
    width: calc(12% - 12px);
  }
  .width-15 {
    width: calc(15% - 12px);
  }
  .width-17{
    width: calc(17% - 12px);
  }
  .width-20 {
    // flex: 1 1 calc(20% - 12px);
    width: calc(20% - 12px);
  }
  .width-25 {
    // flex: 1 1 calc(25% - 12px);
    width: calc(25% - 12px);
  }
  .width-30 {
    // flex: 1 1 calc(30% - 12px);
    width: calc(30% - 12px);
    @media (max-width: 598px) {
      width: 100%;
    }
  }
  .width-35 {
    width: calc(35% - 12px);
  }
  .width-40 {
    width: calc(40% - 12px);
  }
  .width-45 {
    flex: 1 1 calc(45% - 12px);
    width: calc(45% - 12px);
  }
  .width-50 {
    flex: 1 1 calc(50% - 12px);
    width: calc(50% - 12px);
  }
  .width-55 {
    flex: 1 1 calc(55% - 12px);
    width: calc(55% - 12px);
  }
  .width-60 {
    flex: 1 1 calc(60% - 12px);
    width: calc(60% - 12px);
  }
  .width-70 {
    // flex: 1 1 calc(70% - 12px);
    width: calc(70% - 12px);
    @media (max-width: 598px) {
      width: 100%;
    }
  }
  .width-80 {
    flex: 1 1 calc(80% - 12px);
    width: calc(80% - 12px);
  }
  .actions-column {
    min-width: 100px;
  }

  iframe {
    border-width: 0px;
    border-style: none;
    border-color: none;
    border-image: none;
  }

  .mat-radio-button {
    margin: 0 12px;
  }
    
}
.subscriber-container{
  text-align: center;
  width: 70%;
  float: left;
  #subscriber{
    width: 100%;
    video{
      width: 100%;
    }
  }
}
.publisher-container{
    float: right;
    width: 250px;
    position: relative;
    height: auto;
    min-height: 145px;
    text-align: left;
    margin-left: 30px;
    background-color: #ccc;
    #publisher{
      width: 100%;
      max-height: 189px;
      video{
        width: 100%;
      }
    }
}

.facilitator-container{
  display: flex;
  @media screen and (max-width: 1112px) {
    position: absolute;
    top: 0;  
  }
  
  #subscriber {
    position: relative;
    z-index: 1;
    top: 0; bottom: 0; left: 0; right: 0;
    display: flex;
    width: 100%;
    video {
      width: 100%;
    }
  }
}

#publisher {
  position: relative;
  z-index: 2;

  video {
    position: absolute;
    bottom: 0;
    width: 33%;
  }
}


.search-container {
  display: contents;
}
.header-input {
  .mat-form-field-infix {
    width: 200px;

    @media(max-width: 666px) {
      width: 100px !important;
    }
  }
}
.search_by_lr {
  border: 1px solid rgba(0,0,0,.24);
  padding: .20em .75em;
  margin: 0 12px 0 0;
  border-radius: 4px;
  font-size: .65em;
  line-height: 1.125!important;

  @include media(mobile) {
    width: 100%;
  }

  @include media(tablet) {
    width: auto;
    // overflow: auto;
  }

  .mat-form-field-infix {
    width: 200px;
    border-top: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper,
  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
    height: 0;
    background-color: transparent !important;
  }

  .mat-form-field-suffix .mat-icon-button {
    width: 24px;
    height: 24px;

    &:hover {
      background: transparent;
    }

    .mat-icon {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.64);
    }
  }
}

.search_by.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.search_by .mat-input-element::placeholder {
  display: none;
  opacity: 0;
}

.search_by .mat-input-element::placeholder,
.search_by .mat-input-element::-webkit-input-placeholder,
.search_by .mat-input-element::-moz-placeholder {
  opacity: 0;
}
.mat-form-field-wrapper{
  position: relative;
  width: 100%;
  //search bar getting out for small device
  // @media only screen and (max-width: 360px) {
  //   width: 95%;

  // }
  
}
/*customise CSS to override on surveyjs CSS*/
.sd-root--readonly {
  .sd-input {
    background-color: transparent;
    resize: none!important;
    padding: 0;
    box-shadow: none;
    &:focus-within{
      box-shadow: none!important;
    }
  }
  textarea.sd-comment {
    max-height: 60px;
  }
  .sd-remaining-character-counter {
    display: none!important;
  }
  fieldset[role="radiogroup"] {
    .sd-radio {
      display: none;
    }
    .sd-radio--checked {
      display: block;
      padding: 0;
      .sd-radio__decorator {
        display: none;
      }
    }
  }
  .sv-svg-icon {
    display: none;
  }
}

@media screen and (max-width: 1365px) {
  #case-notes .case-note-c2v-header, #referral .referral-c2v{
    width: 100% !important;
  }
}
.action-overview .mat-card .mat-card-header .mat-card-header-text {
  display: block !important;
}

/*custome css for view role responsiveness*/
@media only screen and (max-width: 760px) {
  .seq-theme {
    app-view-role {
      .mat-card {
        &:nth-child(1) {
          tbody {
            display: flex;
            tr {
              min-height: 23px;
              height: auto;
              border: none!important;
              &:nth-child(1) {
                max-width: 42%;
              }
              .mat-cell {
                border: none;
              }
            }
          }
        }
        .mat-table {
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          .mat-header-row {
            display: table-row!important;
            .mat-row {
              height: auto;
              border-bottom: none;
            }
            .mat-header-cell {
              border: none;
              display: flex;
              width: 100%;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              justify-content: center;
              min-height: auto;
              margin: 10px 0;
              min-height: 23px;
            }     
          }
        }
      }
    }
  }
}
